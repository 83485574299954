// Here you can add other styles
.buttonStyle {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3%;
}
.actionButtonsSpacing {
  margin-left: 6px;
  width: max-content;
}
.disabledButton {
  cursor: not-allowed;
}
.closeCursor {
  cursor: pointer;
}
.formStyling {
  flex-direction: row;
}
.buttonsPosition {
  display: flex;
  justify-content: flex-end;
}
.actionsStyling {
  display: flex;
  flex-direction: row;
  border: unset !important;
}
.dashboardCards {
  display: flex;
  flex-direction: row;
}
.tableBackground {
  background-color: #ffffff;
}
.buttonAlign {
  float: right;
}
.cardHeight {
  height: 95%;
}
.profileStyling {
  margin-bottom: 10%;
}
.filterStyling {
  margin-bottom: 2%;
  padding-left: unset;
}
.SurveyFilterStyling{
  margin-bottom: 2%;
  padding-left: unset;
  display: flex;
}
.checkBox {
  margin-top: auto;
}

.wrap .form-control {
  display: inline-block !important;
  width: auto !important;
  margin-left: 0.5rem;
  float: right;
}

.btn-style {
  float: right;
  height: 2.15rem;
}
